// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DashboardGraphBlock($blockId: ID!) {
    graph: dashboardGraphBlock(blockId: $blockId) {
      data
    }
  }
`;

export default QUERY;
